<template>
  <b-modal
    v-model="visible"
    size="lg"
    @hidden="close"
    title="Files"
    title-tag="h3"
    modal-class="modal-primary"
    hide-footer
    centered
  >
    <b-container>
      <b-row v-if="![2,3,4].includes(tabStatus)" class="justify-content-end">
        <b-button
          @click="changeStateAddFile"
          :class="addFile ? 'bg-danger' : 'bg-success'"
          size="md"
          style="border: none; font-size: 15px"
        >
          <feather-icon :icon="addFile ? 'MinusIcon' : 'PlusIcon'" size="15" />
          {{ this.addFile ? "Hide" : "Add File" }}
        </b-button> </b-row
      ><br />
      <b-row v-if="addFile">
        <drag-and-drop
          ref="dyd"
          class="w-100 mb-1"
          v-model="files"
          :filesArray="files"
        />

        <b-col>
          <b-row class="justify-content-center">
            <b-button
              variant="warning"
              @click="uploadFiles"
              :disabled="files.length === 0"
            >
              <feather-icon icon="UploadCloudIcon" /> UPLOAD
            </b-button>
          </b-row>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-table :items="items" :fields="fields" ref="FileTable" show-empty>
          <template #cell(file_name)="data">
            <feather-icon :icon="fileIcon(data.item.file_type)" />
            <a :href="data.item.file_url" target="_blank" class="ml-1">{{
              data.value
            }}</a>
          </template>
          <template #cell(actions)="data">
            <center>
              <feather-icon
                size="18"
                class="text-danger cursor-pointer"
                icon="Trash2Icon"
                @click="deleteFile(data.item.id)"
              />
            </center>
          </template>
        </b-table>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import DragAndDrop from "@/views/commons/utilities/DragAndDrop.vue";
import ApplicationsService from "../../../services/applications.service";
import { mapState, mapGetters } from "vuex";
export default {
  components: { DragAndDrop },
  props: {
    app: { type: String, required: true },
    program: { type: Number, required: true },
    tabStatus: { type: Number, required: true },
  },
  data() {
    return {
      visible: false,
      addFile: false,
      items: [],
      files: [],
      hasFile: false,
    };
  },
  methods: {
    async start() {
      let data = await ApplicationsService.getFiles(this.app);
      this.items = [...data.data];
    },
    async uploadFiles() {
      const formData = new FormData();
      this.files.forEach((file) => {
        formData.append("files[]", file, file.name);
      });
      formData.append("program_id", this.program);
      formData.append("idApp", this.app);
      formData.append("idUser", this.currentUser.user_id);

      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        let result = await ApplicationsService.uploadFiles(formData);

        if (result) {
          this.files = [];
          this.changeStateAddFile();
          this.hasFile = true;
          await this.start();
          this.removePreloader();
          this.showSuccessSwal();
        } else {
          this.removePreloader();
          this.showErrorSwal();
        }
      }
    },
    async deleteFile(id) {
      let data = { idFile: id, idUser: this.currentUser.user_id };
      let confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        this.addPreloader();
        let result = await ApplicationsService.deleteFile(data);
        this.removePreloader();

        if (result) {
          this.showSuccessSwal();
          this.hasFile = true;
          await this.start();
        } else {
          this.showErrorSwal();
        }
      }
    },

    close() {
      this.visible = false;

      this.$emit("close", this.hasFile);
    },
    changeStateAddFile() {
      this.addFile = !this.addFile;
    },
    fileIcon(doc) {
      switch (doc.toLowerCase()) {
        case "pdf":
          return "FileTextIcon";
          break;
        case "docx":
          return "FileTextIcon";
          break;
        case "xlsx":
          return "FileTextIcon";
          break;
        case "png":
          return "ImageIcon";
          break;
        case "jpg":
          return "ImageIcon";
          break;
        default:
          return "FileIcon";
          break;
      }
    },
  },
  computed: {
    ...mapState({
      fields: (state) => state.DebtSolutionApplications.fieldsVerification,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    this.addPreloader();
    await this.start();
    this.removePreloader();
    this.visible = true;
  },
};
</script>