<template>
  <b-modal
    ref="modal-select-agent"
    title="Assign Agent"
    title-tag="h4"
    modal-class="modal-primary"
    @hidden="close"
    size="sm"
    centered
  >
    <b-container>
      <validation-observer ref="form">
        <b-form-group label="Select Agent" label-cols="4">
          <validation-provider v-slot="{ errors }" rules="required">
            <v-select
              transition=""
              v-model="agent"
              :clearable="false"
              label="user_name"
              :class="{ 'border border-danger': errors[0] }"
              :options="sellers"
              :reduce="(option) => option.id"
            />
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-container>
    <template #modal-footer>
      <b-button variant="primary" @click="assignAplicationToAgent"
        >Assign</b-button
      >
    </template>
  </b-modal>
</template>
<script>
import ApplicationsService from "@/views/commons/components/applications/services/applications.service.js";
export default {
  props: {
    aplication_id: {
      type: String,
      required: true,
    },
    sellers: {
      type: Array,
    },
  },
  data() {
    return {
      agent: null,
    };
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
    async assignAplicationToAgent() {
      const result = await this.$refs.form.validate();
      if (result) {
        const res = await this.showConfirmSwal();
        if (res.isConfirmed) {
          this.addPreloader();
          try {
            const res = await ApplicationsService.setAgentToApplication({
              id_app: this.aplication_id,
              id_agent: this.agent,
            });
            if (res.status === 200) {
              this.showSuccessSwal("Agent assigned successfully");
              this.$emit("close", true);
            }
          } catch (error) {
            console.log(error);
            this.showErrorSwal(error);
          } finally {
            this.removePreloader();
          }
        }
      }
    },
  },
  mounted() {
    this.toggleModal("modal-select-agent");
  },
  async created() {},
};
</script>
