export const Filters = [
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
      /* 0 */ label: "From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "To",
      /* 1 */ placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: true,
  },
  {
    type: "select",
    label: "Department",
    margin: true,
    showLabel: true,
    options: [
      { value: 0, label: "All" },
      { value: 2, label: "Boost Credit" },
      { value: 3, label: "Credit Experts" },
      { value: 4, label: "Debt Solution" },
      // { value: 7, label: "Specialist" },
    ],
      /* 2 */ model: "",
    reduce: "value",
    selectText: "label",
    cols: 12,
    md: 2,
    visible: false,
    key: "program"
  },
  {
    type: "select",
    label: "Advisor",
    margin: true,
    showLabel: true,
    options: [],
      /* 3 */ model: "",
    reduce: "id",
    selectText: "name",
    cols: 12,
    md: 2,
    visible: false,
    key: "advisor"
  },
  {
    type: "select",
    label: "Status",
    margin: true,
    showLabel: true,
    options: [
      { value: 4, label: "All" },
        /* 4 */ { value: 0, label: "Pending" },
      { value: 1, label: "Acepted" },
      { value: 2, label: "Rejected" },
    ],
    model: "",
    reduce: "value",
    selectText: "label",
    cols: 12,
    md: 2,
    visible: true,
  },

  {
    type: "datepicker",
    margin: true,
    showLabel: true,
      /* 5 */ label: "Status From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: false,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Status To",
      /* 6 */ placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: false,
  },

  {
    type: "select",
    label: "Result",
    margin: true,
    showLabel: true,
    options: [
      { value: 4, label: "All" },
        /* 7 */ { value: 0, label: "Pending" },
      { value: 1, label: "Approved" },
      { value: 2, label: "Denied" },
    ],
    model: "",
    reduce: "value",
    selectText: "label",
    cols: 12,
    md: 2,
    visible: true,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
      /* 8 */ label: "Result From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: false,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Result To",
      /* 9 */ placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: false,
  },
  {
    type: "select",
    label: "Paid",
    margin: true,
    showLabel: true,
    options: [
      { value: 4, label: "All" },
      { value: 0, label: "Pending" },
        /* 10 */ { value: 1, label: "Yes" },
      { value: 2, label: "No" },
    ],
    model: "",
    reduce: "value",
    selectText: "label",
    cols: 12,
    md: 2,
    visible: false,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Paid From",
    placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: false,
  },
  {
    type: "datepicker",
    margin: true,
    showLabel: true,
    label: "Paid To",
      /* 12 */ placeholder: "Date",
    class: "font-small-3",
    model: null,
    locale: "en",
    dateFormatOptions: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    cols: 6,
    visible: false,
  },
  {
    type: "select",
    label: "Specialist",
    margin: true,
      /* 13 */ showLabel: true,
    options: [],
    model: "",
    reduce: "id",
    selectText: "full_name",
    cols: 12,
    md: 2,
    visible: false,
    key: "specialists"
  },
  {
    type: "select",
    label: "Type",
    margin: true,
      /* 14 */ showLabel: true,
    options: [],
    model: "",
    reduce: "id",
    selectText: "name_charge",
    cols: 12,
    md: 2,
    visible: false,
    key: "type"
  },
]