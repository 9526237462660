<template>
  <b-container fluid>
    <filter-slot
      class="border-table-radius"
      :filter="visibleFilter"
      :filter-principal="filterPrincipal"
      :total-rows="filtersForSlot.totalRows"
      :paginate="filtersForSlot.paginate"
      :start-page="filtersForSlot.startPage"
      :to-page="filtersForSlot.toPage"
      @reload="$refs['applicationsList'].refresh()"
      @reset-all-filters="refreshTable"
    >
      <template #table>
        <b-table
          ref="applicationsList"
          :items="myProvider"
          :fields="visibleFields"
          :per-page="filtersForSlot.paginate.perPage"
          :current-page="filtersForSlot.paginate.currentPage"
          :busy.sync="isBusy"
          sticky-header="600px"
          show-empty
          responsive
          small
          @sort-changed="changeStatusOrder"
          no-provider-filterings
          :foot-clone="false"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <!-- Name Client, Status and Account -->
          <template #cell(name_client)="data">
            <div class="d-flex flex-row">
              <div class="d-flex flex-column">
                <router-link
                  v-if="moduleId != 22"
                  target="_blank"
                  :to="{ path: `${routeC}${data.item.ca_id}` }"
                >
                  {{ data.item.name_client }}
                </router-link>
                <router-link
                  v-else-if="!isAgent"
                  :to="{
                    name: 'ce-customer-service-dashboard',
                    params: {
                      idClient: data.item.ca_id,
                      hasAccess: true, // is alert
                      typeAccess: 6, // Welcome,
                      wayAccess: 4, // enter by modal
                    },
                  }"
                >
                  {{ data.item.name_client }}
                </router-link>
                <span v-else>{{ data.item.name_client }}</span>
                <status-account
                  :account="data.item"
                  :text="true"
                ></status-account>
                <span>{{ data.item.account }}</span>
                <b-badge
                  class="d-flex justify-content-center my-50 text-uppercase"
                  style="max-width: 60px !important"
                  v-if="data.item.program_id === 3"
                  :variant="
                    data.item.client_type_id === 1
                      ? 'light-primary'
                      : 'light-warning'
                  "
                  >{{ data.item.client_type_name }}</b-badge
                >
                <badge-risky-clients
                  :id-account="data.item.ca_id"
                  :risk-level="data.item.risk_level"
                  :risk-description="data.item.risk_description"
                  :risk-type="data.item.risk_type"
                />
              </div></div
          ></template>
          <template #cell(seen)="{ item, value }">
            <FeatherIcon
              icon="EyeIcon"
              @click="markAsSeenApplication(item.app_id, value)"
              :class="
                value == 0 ? 'text-danger cursor-pointer' : 'text-success'
              "
            />
          </template>
          <template #cell(name_advisor)="data">
            <div class="d-flex flex-column">
              <span v-if="Boolean(data.item.name_advisor)"
                >{{ data.item.name_advisor }}
                <feather-icon
                  v-if="hasPermissionAssign"
                  icon="EditIcon"
                  class="clickable text-primary"
                  size="15"
                  @click="addAgentToAplication(data.item)"
                />
              </span>
              <div v-else>
                <b-badge
                  v-if="hasPermissionAssign"
                  variant="warning"
                  pill
                  :class="{ clickable: hasPermissionAssign }"
                  @click="
                    hasPermissionAssign && addAgentToAplication(data.item)
                  "
                  >NO ADVISOR</b-badge
                >
              </div>
            </div>
          </template>
          <template #cell(created_at)="{ item }">
            <div class="d-flex flex-column">
              <span>{{ item.name_user }}</span>
              <span>{{ item.created_at | myGlobalWithHour }}</span>
            </div>
          </template>
          <template #cell(name_charge)="{ item }">
            <div
              :class="[
                item.comment != null ? 'text-primary cursor-pointer' : '',
              ]"
              @click="showComment(item)"
            >
              {{ item.name_charge }}
            </div>
          </template>
          <template #cell(num_responsible)="data">
            <b-badge
              id="span_status"
              :class="StatusColorBackground(data.value)"
              pill
            >
              <span v-if="data.item.num_responsible == 1 && moduleId == 25"
                >IN AGENT</span
              >
              <span v-else> {{ data.item.responsible }} </span>
            </b-badge>
          </template>
          <template #cell(status)="data">
            <span
              v-if="validateStatus(data.item)"
              :class="[StatusColorText(data.item)]"
              v-b-tooltip.hover
              :title="
                data.item.status == 0 && data.item.app_status === 'PENDING'
                  ? 'Waiting for the advisor'
                  : ''
              "
            >
              <span
                v-if="['DELETED', 'EXPIRED'].includes(data.item.app_status)"
                >{{ data.item.app_status }}</span
              >
              <span v-else>{{ data.item.status | statusApplication }}</span>
            </span>
            <status-app
              v-if="!validateStatus(data.item)"
              :data="data"
              :options="statusOptions"
              @refreshTable="refreshTable"
            />
          </template>
          <template #cell(form)="data">
            <div v-if="data.item.status === 1 && inConnection">
              <feather-icon
                :class="[data.item.done_form == 1 ? 'active' : 'inactive']"
                icon="ClipboardIcon"
                size="20"
                @click="openPersonalInfoModal(data.item)"
              />
            </div>
            <div v-else-if="validateForm(data.item)">
              <template v-if="data.item.done_form == 1">
                <div v-b-tooltip.hover title="Completed Form">
                  <b-icon
                    icon="clipboard-check"
                    scale="1.5"
                    @click="openPersonalInfoModal(data.item)"
                    variant="success"
                    class="cursor-pointer"
                  />
                </div>
              </template>
              <template v-else>
                <div
                  v-b-tooltip.hover
                  title="Edit Form"
                  @click="openPersonalInfoModal(data.item)"
                  class="cursor-pointer"
                >
                  <b-icon
                    icon="clipboard"
                    scale="1.5"
                    style="margin-right: 5px"
                    variant="warning"
                  />
                </div>
              </template>
            </div>
          </template>
          <template #cell(status_result)="data">
            <template v-if="data.item.app_status != 'EXPIRED'">
              <span
              v-if="data.item.status_result === 0 && statusApp === 3"
              class="text-uppercase text-danger"
              >Rejected</span
            >
            <status-result
              v-else-if="data.item.done_form == 1"
              :data="data"
              :options="resultOptions"
              @refreshTable="refreshTable"
            />
            </template>
          </template>
          <template #cell(bank_info)="data">
            <feather-icon
              v-if="validateBankInfo(data.item)"
              :class="{
                'text-secondary': data.item.status_result == 0,
                'text-warning':
                  data.item.status_result == 1 && data.item.done_bank == 0,
                'text-success': data.item.done_bank == 1,
              }"
              class="cursor-pointer"
              icon="CreditCardIcon"
              size="20"
              v-b-tooltip.hover
              :title="
                data.item.done_bank == 1
                  ? 'Bank info completed'
                  : 'Waiting for the specialist'
              "
              @click="
                Boolean(data.item.status_result) && openBankInfoModal(data.item)
              "
            />
          </template>
          <template #cell(minimum_amount)="{ item }">
            $ {{ parseFloat(item.minimum_price).toFixed(2) }}
          </template>
          <template #cell(status_paid)="{ item }">
            <template v-if="item.status_paid_name === 'IN PROCESS'">
              <span class="text-warning">VERIFYING</span>
            </template>
            <template
              v-else-if="
                item.app_status === 'COMPLETED' &&
                item.status_paid == 0 &&
                statusApp === 3 &&
                item.status_result === 1
              "
            >
              <span class="text-danger">REJECTED</span>
            </template>
            <template v-else>
              <ChangeStatusP
                v-if="item.status_result == 1 && item.done_bank == 1"
                :item="{ ...item, client_name: item.name_client }"
                :moduleId="moduleId"
                :options="table.optionsStatusPaid"
                :inSpecialist="!inSpecialist"
                :service="'aplications'"
                @send="updateStatus"
                nameProp="status_paid_name"
                option="StatusPaid"
              />
            </template>
          </template>
          <template #cell(amount)="data">
            <div style="text-align: right" class="pr-1">
              <div
                v-if="
                  data.item.amount != null && data.item.suggets_charge != null
                "
                class="d-flex flex-column"
              >
                <span title="Fee" v-b-tooltip.hover>{{
                  data.item.amount | myMoney
                }}</span>
                <span
                  style="margin-top: 5px"
                  title="Suggested charge"
                  v-b-tooltip.hover
                  >{{ data.item.suggets_charge | myMoney }}</span
                >
              </div>
            </div>
          </template>
          <template #cell(verification)="data">
            <feather-icon
              class="cursor-pointer"
              :class="data.item.count_file ? 'text-primary' : ''"
              icon="PaperclipIcon"
              size="20"
              @click="openVerificationModal(data.item.app_id)"
            />
          </template>
          <template #cell(binnacle)="{ item }">
            <tabler-icon
              :badge="
                countMessage(
                  JSON.parse(item.without_seeing),
                  JSON.parse(item.without_seeing_files)
                )
              "
              badge-classes="badge-important"
              icon="BrandMessengerIcon"
              size="25"
              class="text-primary cursor-pointer"
              @click="openModalBinnacle(item)"
            />
            <pre>{{ item.with_seeing_files }}</pre>
          </template>
          <template #cell(tracking)="data">
            <div class="d-flex flex-row justify-content-around px-1">
              <feather-icon
                class="cursor-pointer text-info"
                icon="ListIcon"
                size="20"
                @click="openListTrackingModal(data.item)"
              />
            </div>
          </template>
          <template #cell(app_status)="{ item }">
            <div v-b-tooltip.hover title="Credit card removed">
              <b-icon
                v-if="item.app_status == 'DELETED'"
                icon="trash"
                aria-hidden="true"
                scale="1.5"
                variant="danger"
              ></b-icon>
            </div>
          </template>
          <template #cell(actions)="data">
            <div style="text-align: center">
              <div v-if="statusApp === 1 && inSpecialist">
                <feather-icon
                  v-if="data.item.status === 0 || isSupervisor || isCeo"
                  class="cursor-pointer text-danger"
                  icon="TrashIcon"
                  size="18"
                  @click="openModalDeleteApplication(data.item)"
                />
              </div>
            </div>
          </template>
          <template v-if="statusApp === 2" #custom-foot>
            <b-tr class="bg-dark fixed-footer">
              <b-th :colspan="indexAmount" />
              <b-th>
                <div class="d-flex flex-column" style="font-size: 16px">
                  <b-badge class="rounded-pill mb-1 ml-auto" variant="info"
                    >Sub Total: $ {{ amountSubTotal | currency }}</b-badge
                  >
                  <b-badge class="rounded-pill ml-auto" variant="info"
                    >Total: $ {{ amountTotal | currency }}</b-badge
                  >
                </div>
              </b-th>
              <b-th colspan="5"></b-th>
            </b-tr>
          </template>
        </b-table>
        <br />
      </template>
    </filter-slot>
    <personal-info-modal
      v-if="ActivePersonalInfoModal"
      :id="idApp"
      :completed="doneForm"
      :client_information="client_information"
      @refreshTable="refreshTable"
      @closeModal="closePersonalInfoModal"
    />

    <BankInfoModal
      v-if="ActiveBankInfoModal"
      :row="row"
      @close="closeBankInfoModal"
      @refreshTable="refresh"
    />
    <verification-modal
      v-if="ActiveVerificationModal"
      :app="idApp"
      :program="program_id"
      :tabStatus="tabStatus"
      @close="closeVerificationModal"
    />
    <list-tracking-modal
      v-if="ActiveListTrackingModal"
      :row="row"
      @close="closeListTrackingModal"
    />
    <create-tracking-modal
      v-if="ActiveCreateTrackingModal"
      :row="row"
      @close="closeCreateTrackingModal"
    />
    <delete-application-modal
      v-if="openModalDeleteApplicationController"
      :application="itemToSend"
      @close="openModalDeleteApplicationController = false"
      @reload="refreshTable"
    />
    <select-agent-modal
      v-if="openSelectAgentModal"
      :sellers="sellers"
      :agent_id="agent_id"
      :aplication_id="aplication_id"
      @close="closeSelectAgentModal"
    />
    <Binnacle
      v-if="showModalBinnacle"
      @close="showModalBinnacle = false"
      :clientInformation="clientData"
      @refresh="refreshTable"
    />
    <modal-action
      v-if="activeModal"
      :action="1"
      :data="items[0]"
      :num="3"
      @close="activeModal = false"
    />

    <add-charge-others-modal
      v-if="showAddCharge"
      :action="1"
      @close="closeAddCharge"
      :data="dataClient"
      @refreshTable="refresh"
      :allPaymentMethods="false"
      :sendFrom="sendFrom"
    />

    <b-modal centered v-model="showCommentModal" title="Comment" hide-footer>
      {{ commentSelected }}
    </b-modal>
  </b-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import DeleteApplicationModal from "@/views/commons/components/applications/views/components/modals/DeleteApplicationModal";
import ApplicationsService from "../services/applications.service";
import IconStatusAccount from "./components/icons/IconStatusAccount.vue";
import TotalComponent from "./components/others/TotalComponent.vue";
import PersonalInfoModal from "./components/modals/PersonalInfoModal.vue";
import StatusApp from "./components/others/StatusApp.vue";
import StatusResult from "./components/others/StatusResult.vue";
import StatusPaid from "./components/others/StatusPaid.vue";
import BankInfoModal from "@/views/commons/components/applications/views/components/modals/BankInfoModal.vue";
import VerificationModal from "./components/modals/VerificationModal.vue";
import ListTrackingModal from "./components/modals/ListTrackingModal.vue";
import CreateTrackingModal from "./components/modals/CreateTrackingModal.vue";
import BadgeRiskyClients from "@/views/commons/components/risky-clients/BadgeRiskyClients";
import GlobalService from "@/service/global";
import SelectAgentModal from "@/views/commons/components/applications/views/components/modals/SelectAgentModal.vue";
import FinancialCommissionsService from "@/views/ce-digital/sub-modules/financial/view/commissions/financial-commissions.service";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import ModalAction from "@/views/commons/components/applications/views/components/modals/ModalAction.vue";
import AddChargeOthersModal from "@/views/commons/components/other-services/view/component/modal/AddChargeModal.vue";
import ChangeStatusP from "@/views/commons/financial-approval/ChangeStatusP.vue";

// Binnacle
import Binnacle from "@/views/commons/components/applications/views/components/modals/ModalBinnacleApplications.vue";
// data
import { Filters } from "@/views/commons/components/applications/data/index";

export default {
  components: {
    IconStatusAccount,
    TotalComponent,
    PersonalInfoModal,
    StatusApp,
    StatusResult,
    StatusPaid,
    BankInfoModal,
    VerificationModal,
    ListTrackingModal,
    CreateTrackingModal,
    DeleteApplicationModal,
    BadgeRiskyClients,
    SelectAgentModal,
    Binnacle,
    ModalAction,
    AddChargeOthersModal,
    ChangeStatusP,
    StatusAccount,
  },
  props: { program_id: { required: true, type: Number } },
  async created() {
    this.fields[3].label = "Recommended by";
    this.filterColumn("actions", this.inSpecialist && this.isTabPending);
    this.filterColumn("app_status", this.isTabUnsuccessful);
    const params = {
      module_id: this.moduleId,
      user_id: this.currentUser.user_id,
      role_id: this.currentUser.role_id,
    };
    let data;

    switch (this.moduleId) {
      case 11:
      case 25:
      case 28:
      case 29:
        data = await ApplicationsService.getSpecialists();
        this.getFiltersByKey("advisor").visible = false;
        this.getFiltersByKey("specialists").visible = [1, 2].some(
          (rol) => rol == this.roleId
        );
        this.getFiltersByKey("specialists").options = data.data;
        this.getFiltersByKey("program").visible = true;
        break;
      case 20:
        await this.getAgentsConnection();
        this.getFiltersByKey("advisor").visible = true;
        this.getFiltersByKey("advisor").options = this.agentsConnection;
        this.sellers = this.agentsConnection;
        break;

      case 22:
        await this.getAdvisorsAction({ ...params });
        this.getFiltersByKey("advisor").options = this.advisors;
        this.getFiltersByKey("advisor").visible = true;

        const sellers = await GlobalService.getSellers(22, {
          roles: "[]",
          type: "0",
        });
        this.sellers = sellers.data;
        this.getFiltersByKey("advisor").selectText = "user_name";
        this.getFiltersByKey("advisor").options = sellers.data;
        break;

      default:
        this.getFiltersByKey("advisor").visible = ![3, 15].some(
          (el) => el == this.roleId
        );
        if (this.filters[3].visible) {
          await this.getAdvisorsAction({ ...params });
          this.getFiltersByKey("advisor").options = this.advisors;
        }
        break;
    }

    this.getTypesChargesUsed();
  },
  mounted() {
    this.$emit("table", this.$refs.applicationsList);
  },
  data() {
    return {
      isBusy: false,
      items: [],
      showAddCharge: false,
      sendFrom: "app_main",
      filtersForSlot: {
        totalRows: 0,
        paginate: {
          perPage: 10,
          currentPage: 1,
        },
        startPage: 1,
        toPage: 1,
        filterPrincipal: {
          type: "input",
          inputType: "text",
          placeholder: "Search by name...",
          model: "",
        },
      },
      amountT: 0,
      doneForm: null,
      idApp: "",
      row: {},
      ActivePersonalInfoModal: false,
      ActiveBankInfoModal: false,
      ActiveVerificationModal: false,
      ActiveListTrackingModal: false,
      ActiveCreateTrackingModal: false,
      statusOptions: [
        { value: null, text: "" },
        { value: 1, text: "ACCEPTED" },
        { value: 2, text: "REJECTED" },
      ],
      resultOptions: [
        { value: null, text: "" },
        { value: 3, text: "IN PROCESS" },
        { value: 1, text: "APPROVED" },
        { value: 2, text: "DENIED" },
      ],
      paidOptions: [
        { value: null, text: "" },
        { value: 1, text: "YES" },
        { value: 2, text: "NO" },
      ],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      filters: Filters,
      order: "desc",
      openModalDeleteApplicationController: false,
      itemToSend: {},
      sellers: [],
      aplication_id: "",
      agent_id: "",
      openSelectAgentModal: false,
      showModalBinnacle: false,
      activeModal: false,
      table: {
        optionsStatus: ["", "ACCEPTED", "REJECTED"],
        optionsStatusResult: ["PENDING", "APPROVED", "DENIED"],
        optionsStatusPaid: ["", "YES", "NO"],
      },
      routesModules: {
        5: "debt-solution-applications",
        6: "credit-experts-applications",
        7: "boost-credit-applications",
        20: "connection-applications",
        22: "customerservice-applications",
        29: "financial-approval-applications",
      },
      moduleToProgram: {
        5: 4,
        6: 3,
        7: 2,
        20: 3,
        22: 3,
        29: null,
      },
      showCommentModal: false,
      commentSelected: null,
    };
  },
  methods: {
    ...mapMutations({
      fieldsTableCompleted: "DebtSolutionApplications/getFieldsCompleted",
      fieldsTableInProcess: "DebtSolutionApplications/getFieldsInProcess",
      fieldsForSpecialistsDigital:
        "DebtSolutionApplications/fieldsForSpecialistsDigital",
      M_PENDING_APPLICATIONS: "DebtSolutionApplications/M_PENDING_APPLICATIONS",
    }),
    ...mapActions({
      getAdvisorsAction: "DebtSolutionApplications/getAdvisorsAction",
      getAgentsConnection: "DebtSolutionApplications/A_GET_AGENTS_CONNECTION",
      A_GET_PENDING_APPLICATIONS:
        "DebtSolutionApplications/A_GET_PENDING_APPLICATIONS",
    }),
    addAgentToAplication(item) {
      this.aplication_id = item.app_id;
      this.openSelectAgentModal = true;
    },
    closeSelectAgentModal(val) {
      if (val) {
        this.refreshTable();
      }
      this.openSelectAgentModal = false;
    },
    async getTypesChargesUsed() {
      let data = "";
      data = await ApplicationsService.getTypesChargesUsed();
      this.getFiltersByKey("type").options = data.data;
    },
    async myProvider(context) {
      const params = {
        id_user: this.currentUser.user_id,
        role_id: this.isTeamLeader ? 2 : this.currentUser.role_id,
        orden: this.order,
        orderby: "created_at",
        _page: context.currentPage,
        _per_page: context.perPage,
        campo: this.filterPrincipal.model || null,
        f_from: this.filters[0].model,
        f_to: this.filters[1].model,
        f_program: this.inSpecialist ? this.filters[2].model : this.program_id,
        f_advisor:
          this.isCeo || this.isSupervisor || this.isTeamLeader || this.isChief
            ? this.filters[3].model
            : this.inSpecialist
            ? ""
            : this.currentUser.user_id,
        f_status: this.filters[4].model,
        f_result: this.filters[7].model,
        f_paid: this.filters[10].model,
        f_specialist:
          this.isCeo || this.isSupervisor || this.isTeamLeader
            ? this.filters[13].model
            : null,
        f_statusApp: this.statusApp, // los estados son 1 para IN PROCESS, 2 para COMPLETED y 3 para DELETED
        f_resultfrom: this.filters[8].model,
        f_resultto: this.filters[9].model,
        f_paidfrom: this.filters[11].model,
        f_paidto: this.filters[12].model,
        typeCharge: this.filters[14].model,
        f_statusfrom: this.filters[5].model,
        f_statusto: this.filters[6].model,
        module_id: this.moduleId,
        is_digital: null,
      };

      const data = await ApplicationsService.searchApplicationAdvisor({
        ...params,
      });

      let access = { data }.data.data;
      this.items = access.data;
      this.amountT = this.items.length != 0 ? access.data[0].total : 0;
      this.path = access.path;
      this.filtersForSlot.paginate.currentPage = access.current_page;
      this.filtersForSlot.paginate.perPage = access.per_page;
      this.filtersForSlot.startPage = access.from;
      this.filtersForSlot.totalRows =
        access.data.length > 0 ? access.data[0].cc : 0;
      this.filtersForSlot.toPage = access.to;

      // this.refreshCounterInSidebar();
      if (this.items.length == 0) {
        this.isBusy = false;
      } else {
        return this.items;
      }
    },
    async markAsSeenApplication(applicationId, seen) {
      if (seen === 1) return;

      const isOk = await ApplicationsService.markAsSeenApplication(
        applicationId
      );
      if (isOk) {
        this.showSuccessSwal("Success", "ApplicationView");
        this.refreshTable();
      }
    },
    async openModalDeleteApplication(item) {
      this.itemToSend = item;
      this.openModalDeleteApplicationController = true;
    },
    async finishApplication(item) {
      const confirm = await this.showConfirmSwal();
      if (confirm.isConfirmed) {
        const app_id = item.app_id;
        const params = {
          id_app: app_id,
          clientAccountId: item.ca_id,
          userId: this.currentUser.user_id,
        };
        this.addPreloader();
        try {
          const result = await ApplicationsService.updateStatusApplication(
            params
          );
          if (result.status === 200) {
            if (item.client_type_id === 2 && Boolean(item.capture_by)) {
              const body = {
                slug: "aplication-cs",
                commision_type: 1,
                agent_id: item.capture_by,
                module_id: this.moduleId,
                client_account: item.ca_id,
                base_amount: item.amount,
              };
              await FinancialCommissionsService.createCommissionCed(body);
            }
            this.showSuccessSwal();
            this.refreshTable();
          }
        } catch (error) {
          this.showErrorSwal(error);
        } finally {
          this.removePreloader();
        }
      }
    },
    StatusColorBackground(status) {
      switch (status) {
        case 1:
          return ["bg-light-warning"];
        case 2:
          return ["bg-light-primary"];
        case 3:
          return ["bg-light-success"];
        default:
          return "bg-secondary text-light";
      }
    },
    StatusColorText({ status, app_status }) {
      if (app_status === "DELETED" || app_status === "EXPIRED")
        return "text-danger";

      switch (status + 1) {
        case 1:
          return "text-warning";
        case 2:
          return "text-success";
        case 3:
          return "text-danger";
        default:
          return "bg-secondary text-light";
      }
    },
    countMessage(messages, messageFile) {
      let mess = messages || [];
      let file = messageFile || [];
      let joinMessage = mess.concat(file);
      if (
        joinMessage.length > 0 &&
        joinMessage[0].user_id !== this.currentUser.user_id
      ) {
        return joinMessage.length;
      }
    },
    openAddCharge(data) {
      this.dataClient = data;
      this.dataClient.account_id = data.ca_id;
      this.dataClient.client_account = data.account;
      this.dataClient.client_name = data.name_client;
      this.dataClient.type_description = data.full_charge;
      this.dataClient.id = data.app_id;
      this.dataClient.lead_id = data.lead_id;
      this.dataClient.charge_description = data.name_charge;
      this.dataClient.account = data.account;
      this.dataClient.type_id = data.type;
      this.showAddCharge = true;
    },
    modifyVisibility(nameKey, isVisible) {
      let keyField = this.fields.findIndex((item) => item.key == nameKey);
      if (keyField != -1) {
        this.fields[keyField].visible = isVisible;
      }
    },
    openPersonalInfoModal(data) {
      data.done_form ? (this.doneForm = true) : (this.doneForm = false);
      this.idApp = data.app_id;
      this.client_information = data;
      this.ActivePersonalInfoModal = true;
    },
    closePersonalInfoModal() {
      this.$bvModal.hide("personal-info");
      this.ActivePersonalInfoModal = false;
    },
    showComment(item) {
      this.commentSelected = item.comment;
      this.showCommentModal = item.comment != null;
    },
    async openBankInfoModal(row) {
      try {
        this.addPreloader();
        const { data } = await ApplicationsService.getBankInfo({
          id: row.app_id,
        });
        if (data && data.length > 0) {
          const dataBank = {
            id: data[0].app_id,
            client_account_id: data[0].ca_id,
            ...data[0],
            bank_id: data[0].bank,
          };
          this.row = dataBank;
          this.ActiveBankInfoModal = true;
          this.removePreloader();
        }
      } catch (ex) {
        this.removePreloader();
      }
    },
    async saveBankInfo(value) {
      try {
        const dataBank = {
          ...value,
          b_app_id: value.b_id,
          done_status: value.b_done_status,
        };
        delete dataBank.b_id;
        delete dataBank.b_done_status;
        this.addPreloader();
        const result = await ApplicationsService.saveBankInfo(dataBank);
        if (result) {
          this.refreshTable();
          this.showSuccessSwal();
          this.closeBankInfoModal();
        }
        this.removePreloader();
      } catch (ex) {
        this.removePreloader();
      }
    },
    closeBankInfoModal() {
      this.ActiveBankInfoModal = false;
    },
    openVerificationModal(app) {
      this.idApp = app;
      this.ActiveVerificationModal = true;
    },
    closeAddCharge() {
      this.showAddCharge = false;
    },
    refresh() {
      this.showAddCharge = false;
      this.$refs.applicationsList.refresh();
      this.refreshCounterInSidebar();
    },
    closeVerificationModal(value) {
      if (value) {
        this.$refs.applicationsList.refresh();
      }
      this.ActiveVerificationModal = false;
    },
    openListTrackingModal(row) {
      this.row = row;
      this.ActiveListTrackingModal = true;
    },
    closeListTrackingModal() {
      this.ActiveListTrackingModal = false;
    },
    openCreateTrackingModal(row) {
      this.row = row;
      this.ActiveCreateTrackingModal = true;
    },
    closeCreateTrackingModal() {
      this.ActiveCreateTrackingModal = false;
    },
    openModalBinnacle(item) {
      try {
        this.showModalBinnacle = true;
        this.clientData = {
          name_client: item.name_client,
          module: item.module,
          created_at: item.created_at,
          name_advisor: item.name_advisor,
          name_charge: item.full_charge,
          id: item.app_id,
          section: "application",
          recommended_by: item.name_user,
        };
      } catch (ex) {
        this.clientData = null;
        console.log(ex);
      }
    },
    changeStatusOrder() {
      this.order = this.order === "asc" ? "desc" : "asc";
    },
    refreshTable() {
      this.$refs.applicationsList.refresh();
      this.refreshCounterInSidebar();
    },
    getFiltersByKey(key) {
      return this.filters.find((filter) => filter.key === `${key}`);
    },
    filterColumn(key, bool) {
      let field = this.fields.find((field) => field.key == `${key}`);
      field.visible = bool;
    },
    openModalAction() {
      this.activeModal = true;
    },
    validateStatus(data) {
      return (
        data.program_id != 7 &&
        this.inSpecialist &&
        this.currentUser.role_id !== 8
      );
    },
    validateForm(data) {
      return (
        (data.status === 1 &&
          this.inSpecialist &&
          this.currentUser.role_id !== 8) ||
        (data.status === 1 &&
          (!this.inSpecialist || this.currentUser.role_id === 8))
      );
    },
    validateBankInfo(data) {
      return (
        (data.status === 1 &&
          data.status_result == 1 &&
          this.inSpecialist &&
          this.currentUser.role_id !== 8) ||
        (data.status === 1 &&
          data.status_result == 1 &&
          (!this.inSpecialist || this.currentUser.role_id === 8))
      );
    },
    async updateStatus(data) {
      let _data = {
        id_app: data.n_app_id,
        modul: data.n_module_id,
        userId: data.n_user_id,
        n_action: data.n_action,
        n_option: data.n_option,
        t_comment: data.n_comment,
      };
      try {
        this.addPreloader();
        const { n_option, n_action } = data;
        if (n_option === "StatusPaid") {
          if (n_action === "NO" || n_action === "PENDING") {
            await ApplicationsService.updateStatusApplication(_data);
            this.refreshTable();
          } else {
            this.openAddCharge(data);
          }
        } else {
          await ApplicationsService.updateStatusApp(_data);
          this.refreshTable();
        }
      } catch (ex) {
        this.showErrorSwal(ex);
      } finally {
        this.removePreloader();
      }
    },
    async refreshCounterInSidebar() {
      await this.A_GET_PENDING_APPLICATIONS(this.moduleId);
    },
  },
  computed: {
    ...mapState({
      fieldsTable: (state) => state.DebtSolutionApplications.fields,
      advisors: (state) => state.DebtSolutionApplications.advisors,
    }),
    ...mapGetters({
      currentUser: "auth/currentUser",
      agentsConnection: "DebtSolutionApplications/G_AGENTS_CONNECTION",
    }),
    routeC() {
      let route;
      switch (this.moduleId) {
        case 5:
          route = "/debtsolution/clients/account/";
          break;
        case 6:
          route = "/creditexperts/clients/account/";
          break;
        case 7:
          route = "/boostcredit/clients/account/";
          break;
        case 20:
          route = "/cedigital/connection/clients/account/";
          break;
        case 25:
          route = "/specialistdigital/clients/account/";
          break;
        case 29:
          route = "/specialists/financial-approval/clients/account/";
          break;
      }

      return route;
    },
    isTeamLeader() {
      return this.currentUser.role_id === 11 && this.moduleId === 6;
    },
    moduleId() {
      return Number(this.$route.matched[0].meta.module);
    },
    roleId() {
      return this.currentUser.role_id;
    },
    statusApp() {
      return this.$route.meta.statusApp;
    },
    isTabUnsuccessful() {
      return this.$route.meta.statusApp == 3;
    },
    isTabSuccessfull() {
      return this.$route.meta.statusApp == 2;
    },
    isTabPending() {
      return this.$route.meta.statusApp == 1;
    },
    isTabExpired() {
      return this.$route.meta.statusApp == 4;
    },
    tabStatus() {
      return this.$route.meta.statusApp;
    },
    amountTotal() {
      return parseFloat(this.amountT);
    },
    amountSubTotal() {
      let suma = 0;
      this.items.map((item) => {
        suma += parseFloat(item.amount);
      });
      return suma;
    },
    fields() {
      this.fieldsTable[1].visible = false;
      if (this.moduleId === 20) {
        this.fieldsTable[1].visible = true;
      }
      return this.fieldsTable;
    },
    visibleFields() {
      this.modifyVisibility("status_result", !this.isTabSuccessfull);
      this.modifyVisibility("status", !this.isTabSuccessfull);
      this.modifyVisibility("status_paid", !this.isTabSuccessfull);
      this.modifyVisibility("num_responsible", this.isTabPending);
      return this.fields.filter((field) => field.visible);
    },
    isNotAgent() {
      return [1, 2, 14, 17].includes(this.currentUser.role_id);
    },
    inSpecialist() {
      return [11, 25, 28, 29, 30].includes(this.moduleId);
    },
    inConnection() {
      return this.moduleId === 20;
    },
    hasPermissionAssign() {
      return [20, 22].includes(this.moduleId) && this.isNotAgent;
    },
    visibleFilter() {
      if (this.inSpecialist) {
        this.filters[14].visible = true;

        if (
          this.filters[4].model == 0 ||
          this.filters[4].model == 1 ||
          this.filters[4].model == 2
        ) {
          this.filters[5].visible = true;
          this.filters[6].visible = true;
        } else {
          this.filters[5].visible = false;
          this.filters[6].visible = false;
          this.filters[5].model = null;
          this.filters[6].model = null;
        }

        if (this.filters[7].model != 4 && this.filters[7].model != null) {
          this.filters[8].visible = true;
          this.filters[9].visible = true;
        } else {
          this.filters[8].visible = false;
          this.filters[9].visible = false;
          this.filters[8].model = null;
          this.filters[9].model = null;
        }

        if (this.filters[10].model == 1 || this.filters[10].model == 2) {
          this.filters[11].visible = true;
          this.filters[12].visible = true;
        } else {
          this.filters[11].visible = false;
          this.filters[12].visible = false;
          this.filters[11].model = null;
          this.filters[12].model = null;
        }
      } else {
        this.filters[14].visible = false;
      }

      return this.filters.filter((filter) => filter.visible);
    },
    indexAmount() {
      return this.fields
        .filter((item) => item.visible)
        .findIndex((item) => item.key == "amount");
    },
  },
};
</script>
<style scoped>
#span_status {
  padding: 5px 10px;
  border-radius: 15px;
  width: 150px;
}
.active {
  color: var(--success);
  cursor: pointer;
}
.inactive {
  color: var(--secondary);
  cursor: pointer;
}
.fixed-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
}
</style>
