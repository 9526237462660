<template>
  <b-modal
    v-model="onControl"
    size="lg"
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    title="Delete Credit Card"
    @hidden="close"
    no-close-on-esc
    no-close-on-backdrop
  >
    <validation-observer ref="form">
      <validation-provider
        v-slot="{ errors }"
        name="account_type"
        rules="required"
      >
        <b-form-group
          label="Comment"
        >
          <b-form-textarea
            v-model="comment"
            :state="errors[0] ? false : null"
          />
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <template #modal-footer>
      <b-button
        variant="success"
        @click="deleteApplication"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ApplicationsService from '@/views/commons/components/applications/services/applications.service'
import { mapGetters } from 'vuex'

export default {
  props: {
    application: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      comment: '',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  created() {
    this.onControl = true
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async deleteApplication() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            this.addPreloader();
            const params = {
              id_app: this.application.app_id,
              user_id: this.currentUser.user_id,
              id_client: this.application.ca_id,
              app_comment: this.comment,
            }
            const data = await ApplicationsService.appDeleteApplication(params)
            if (data.status === 200) {
              this.showSuccessSwal('Credit Card successfully deleted')
              this.close()
              this.$emit('reload')
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      } finally {
        this.removePreloader();
      }
    },
  },
}
</script>
